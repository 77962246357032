@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share&display=swap');
/*
  viola scuro: #996c95
  viola chiaro: #d7cbd5
*/
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  margin: auto;
  color: black;
  background-color: white;
}

input, select, .custom-control-label::before {
  background-color: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  border-radius: 8px !important;
}

.fixed-top {
  background-color: white;
}

.page-wrap {
  /*padding-top: 430px;*/
  padding-top: 30px;
  background-color: #f2f2f2;
  min-height: 500px;
}

.bg-primary, .dropdown-menu {
  background-color: #e5ddda !important;
}

#root {
  /*background-color:#f2f2f2;*/
}
#app {
  background-color: white;
}

#the-iframe-1 { text-align: center; }
#the-iframe-2 { text-align: center; }

#profilo-mobile { display: none; }

#ico-up-mobile {
  display: none;
  margin-top: -30px;
  margin-bottom: 20px;
}

.access-input {
  width: 365px !important;
}

a {
  color: #996c95 !important;
}

.a-strong {
  color: #996c95 !important;
}

a:hover {
  color:#996c95 !important;
  text-decoration: underline;
}

.titolo-pagina {
  color: #996c95;
  font-weight: bold;
  font-size: 1.2rem;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 15px;
}

.subnavigator {
  height: 50px;
  padding-top: 12px;
  background-color: #e5ddda;
}

.filetto-1 {
  height: 20px;
  padding: 0px !important;
  background-color: #996c95 !important;
}
.filetto-2 {
  height: 2px;
  padding: 0px !important;
  background-color: #996c95 !important;
}

.icona {
  height: 22px;
}
.icona2 {
  height: 24px;
}

.container {
  max-width: 1240px;
}

.container-bs {
  max-width: 798px;
}

.container-1024 {
  max-width: 1024px;
}

.container-filetto {
  max-width: 1155px;
}

.container-login {
  max-width: 600px;
}

.top {
  background-color: white;
  height: 60px;
  width: 100%;
}

.navbar {
  z-index: 10;
  padding: 0.2rem;
  padding-left: 0.5rem;
  background-color: #996c95;
}
.navbar a:hover {
  color:#996c95 !important;
  text-decoration: underline;
}

.nav-link {
  color: black !important;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgb(0, 0, 0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar-dark .navbar-toggler {
  border: 0px;
  margin-left: -13px;
}

.navbar-nav .voci-dx-1 {
  margin-left: 640px;
}

.navbar-nav .voci-dx-2 {
  margin-left: 572px;
}

.btn-link {
  text-decoration: underline;
}

.btn-primary, .btn-primary:link, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
  background-color: #996c95;
  border-color: #996c95;
}

.form-control, .custom-select, .custom-control {
  color: black !important;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 5px;
}

.iframe-account {
  width: 100%;
  height: 1650px;
  border: 0;
  border-radius: 8px;
}

.pre-footer {
  font-size: 0.7rem;
  background-color: #e5ddda;
}
.footer {
  font-size: 0.7rem;
  background-color: #545454;
}
.footer a {
  color: #ffffff !important;
}

.logo-sponsor {
  height: 30px;
}

.logo-ctcae {
  height: 50px;
}

.logo-fs {
  height: 30px;
}

.logo-fs-splash {
  text-align: right;
}

.intro {
  margin-bottom: -20px !important;
}

.continua {
  font-size: 0.8rem;
}

.sponsor {
  margin-top: 50px;
  margin-bottom: 50px;
}

.header a {
  color: black !important;
}

.menu-home {
  background-color: #971613;
}
.menu-home a {
  color: black !important;
  margin: 10px !important;
  font-size: 1.4rem !important;
}
.menu-home a:hover {
  color:#996c95 !important;
  text-decoration: underline !important;
}
.menu-home .voce-sx {
  padding: 20px;
}
.menu-home .voce-sx .bottone-sx {
  margin-right: 50px;
}
.menu-home .voce-dx {
  padding: 20px;
}

#menu-mobile {
  display: none;
}

.menu-mobile {
  z-index: 1;
  position: relative;
  padding: 5px;
  padding-top: 10px;
  bottom: 10px;
  background-color: #996c95;
  text-align: left;
}
.menu-mobile a {
  margin: 10px;
  font-size: 1.1rem;
}
.menu-mobile .tendina {
  opacity: 1;
  padding: 0;
  width: 170px;
  height: auto;
  border: none;
  cursor: pointer;
  background: none;
  margin-left: 7px;
  font-size: 1.1rem;
  color: black !important;
  background-color: #996c95;
}

.filtro {
  padding: 0 !important;
}
.filtro-in {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.filtro-label {
  color: white;
  background-color: #996c95;
  padding: 1px !important;
  padding-left: 7px !important;
  padding-bottom: 4px !important;
  border-radius: 8px;
}

.lemmi {
  padding: 10px;
  height: 305px;
  overflow: auto;
  margin-top: -6px;
  background-color: white;
  border: 1px solid #f2f2f2;
  border-top: 1px solid #996c95;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.lemma {
  margin-bottom: 5px;
}

.lemma a {
  cursor: pointer;
}

.lemma-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.l-bookmark {
  max-height: 500px;
  overflow: scroll;
}

.link {
  cursor: pointer;
}

.link_footer {
  color: white;
}

.scheda {
  height: 500px;
  padding: 10px;
  margin-bottom: 50px;
  overflow: auto;
  background-color: #d7cbd5;
  color: black !important;
  opacity: 0.7;
  border-radius: 8px;
}
.scheda .bold {
  font-weight: bold;
}
.scheda .nome {
  padding: 5px;
  color: black;
  /*background-color: #996c95;*/
}
.scheda .sinonimi, .scheda .vedi {
  padding-top: 5px;
  padding-left: 10px;
  background-color: #f2f2f2;
}
.scheda .spiega {
  padding: 8px;
  background-color: white;
  border-radius: 8px;
}

.glossario {
  margin-bottom: 50px;
}

.btn-100 {
  width: 100%;
}

.ridotto {
  font-size: 0.9rem;
}

.back {
  margin-bottom: 10px;
}

.aiutino {
  margin-top: 30px;
  margin-bottom: 30px;
}

.benvenuto {
  font-weight: bold;
  color: #971613;
}

.aggiornamenti {
  margin-top: 70px;
  margin-bottom: 17px;
}

.recaptcha, .g-recaptcha {
  margin-bottom: 15px;
}

.loading {
  text-align: center;
}

.email {
  width: 300px;
}

.cookie {
  color: black;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  padding-top: 5px;
}

.cookie .btn {
  margin: 10px;
  width: 180px;
}

.cookie a {
  color: #143B68;
}

/* SPLASH */
img {max-width: 100%;}
.testa .text-center {padding-left: 0;padding-right: 0;}
.testa img {max-width: 160px;}
.bg-bianco {background-color:#ffffff;}
.bg-grigio {background-color:#f2f2f2; border-bottom: 5px solid #996c95; margin-top: -5px;}
.bg-blu {background-color:#986C93; color:#ffffff;}
.bg-blu p {margin-top: 0.5rem; margin-bottom: 0.5rem;}
.entra {color:#986C93;}
.btn-entra {color:white !important; font-size: 1.3rem; font-weight: bold;}
.btn-entra:hover {background-color:white !important;}
.banner {padding: 2rem 0;}
.banner .container {max-width: 100%;}
.autore {font-family: 'Share', sans-serif; font-size: 1.1rem;}
.sponsor-splash {padding-top:2em}

@media only screen and (max-width: 1025px) {
  .navbar-nav .voci-dx-1 {
    margin-left: 635px;
  }
}

@media only screen and (max-width: 1023px) {
  .navbar-nav .voci-dx-1 {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 767px) {

  .top {
    height: 65px;
  }
  .menu-home .voce-sx {
    padding: 10px;
  }
  .menu-home .voce-sx .bottone-sx {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .menu-home .voce-dx {
    padding: 10px;
  }
  .menu-home .vuoto {
    line-height: 4px;
  }

  .news .testo {
    border-bottom: 10px solid #dddddd;
  }

  .aggiornamenti, .sponsor {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .navbar-nav .voci-dx-1 {
    margin-left: 0px;
  }

  .iframe-account {
    height: 1800px;
  }
  .text-small {
    font-size: 0.9rem;
  }

  .logo-fs {
    height: 25px;
  }

  .logo-fs-splash {
    text-align: center;
  }

  .logo-ctcae {
    height: 40px;
  }

  .logo-sponsor {
    height: 30px;
  }

  #introduzione {
   display: none;
  }
  .navbar {
    min-height: 10px;
  }
  #ico-up-mobile {
    display: block;
  }

  .autore {font-size: 0.9rem;}
  .testo-splash {font-size: 0.9rem;}

  .showcase img {display: none;}

  /*
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
  */
  /*
  .top {
    height: 80px;
  }
  .navbar-toggler {
  position: absolute;
  top: -70px;
  }
  #logo-ctcae {
    position: absolute;
    left: 80px;
    top: 30px;
  }
  #logo-fs {
    position: absolute;
    left: 105px;
    top: -10px;
  }
  #profilo-mobile {
    display: block;
    position: absolute;
    right: 18px;
    top: 35px;
  }
  */
}

@media screen and (min-width:768px) {
  .showcase img {margin-top: -30%;}
}
/*# sourceMappingURL=App.css.map */
